#root {
  width: 100%;
  height: 100%;
}
.gi {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: var(--layout-background);
}
.gi-navbar {
  display: flex;
  flex-basis: 40px;
  background-color: rgba(0, 0, 0, 0);
}
.gi-analysis {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 4px;
  overflow: hidden;
}
.gi-analysis-nav {
  display: flex;
  flex-shrink: 0;
  width: 50px;
  height: 100%;
}
.gi-analysis-sidebar {
  z-index: 9;
  display: flex;
  flex-basis: 50px;
  flex-shrink: 0;
  width: 50px;
  height: 100%;
  color: var(--primary-text-color);
  background-color: rgba(0, 0, 0, 0);
}
.gi-analysis-conf {
  z-index: 8;
  flex-basis: 345px;
  flex-shrink: 0;
  width: 345px;
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s ease;
}
.gi-analysis-conf.collapse {
  flex-basis: 0px;
  width: 0px;
  opacity: 0;
  transition: all 0.2s ease;
}
.gi-analysis-conf::-webkit-scrollbar {
  width: 0 !important;
}
.gi-analysis-workspace {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 8px;
}
.gi-analysis-canvas {
  position: relative;
  width: 100%;
  height: 100%;
}
.gi-analysis-footer {
  flex-basis: 200px;
  background: lightslategray;
}
.datav-gui {
  box-shadow: 0px 0px 6px 2px #ddd;
}
.ant-tabs-content {
  padding: 0px 3px;
}
