.gi-side-tabs {
  width: 100%;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}
.gi-side-tabs .ant-tabs {
  height: 100%;
}
.gi-side-tabs .ant-tabs-tab {
  justify-content: center;
  margin-top: 0px;
  padding: 8px 12px !important;
  text-align: center;
}
.gi-side-tabs .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.gi-side-tabs .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  height: 100%;
  padding: 0px;
}
.gi-side-tabs .ant-tabs-top > .ant-tabs-nav,
.gi-side-tabs .ant-tabs-bottom > .ant-tabs-nav,
.gi-side-tabs .ant-tabs-top > div > .ant-tabs-nav,
.gi-side-tabs .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 8px;
}
.gi-side-tabs .ant-tabs-tabpane .ant-tabs-tabpane-active {
  height: 100%;
}
.gi-side-tabs .ant-tabs-content {
  box-sizing: border-box;
  height: 100%;
  padding: 8px;
  overflow-y: auto;
}
.gi-side-tabs-container {
  background: var(--background-color);
}
